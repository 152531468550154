import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";

class LifeFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [
        {
          slug: "lifeLogin",
          title: "Regarding the use and login of the “e+Life” App",
          tab: "public",
        },
        {
          slug: "lifeLogin",
          title: "Regarding the use and login of the “e+Life” App",
          tab: "school",
        },
        {
          slug: "lifeParkGamePublic",
          title: "Regarding “e+ Go to Park” Game",
          tab: "public",
        },
        {
          slug: "lifeParkGame",
          title:
            "Regarding the school challenge mode of the “e+ Go to Park” Game",
          tab: "school",
        },
        {
          slug: "lifeTech",
          title: "Regarding Technical Issues, Security and Privacy",
          tab: "school",
        },
        {
          slug: "lifeTech",
          title: "Regarding Technical Issues, Security and Privacy",
          tab: "public",
        },
        {
          slug: "lifeCoin",
          title: "Regarding Health Coins",
          tab: "school",
        },
        {
          slug: "lifeCoin",
          title: "Regarding Health Coins",
          tab: "public",
        },
        {
          slug: "lifeEnquiry",
          title: "Enquiry",
          tab: "school",
        },
        {
          slug: "lifeEnquiry",
          title: "Enquiry",
          tab: "public",
        },
        /*{
          slug: "lifeWalking",
          title: "Regarding Walking Challenge under “10,000 Steps a Day” Event",
          tab: "school",
        },
        {
          slug: "lifeWalking",
          title: "Regarding Walking Challenge under “10,000 Steps a Day” Event",
          tab: "public",
        },*/
      ],
      questions: [
        {
          isOpen: false,
          sectionSlug: "lifeLogin",
          slug: "use-life-not-registered-ehealth",
          question: "life-faq-login-q1-question",
          answer: "life-faq-login-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeLogin",
          slug: "login-life",
          question: "life-faq-login-q2-question",
          answer: "life-faq-login-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeLogin",
          slug: "register-life-acc",
          question: "life-faq-login-q3-question",
          answer: "life-faq-login-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeLogin",
          slug: "register-login-under-16",
          question: "life-faq-login-q4-question",
          answer: "life-faq-login-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeParkGame",
          slug: "how-to-join-park-game",
          question: "life-faq-park-q1-question",
          answer: "life-faq-park-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeParkGame",
          slug: "eligibility-for-schools-to-join-school-challenge",
          question: "life-faq-park-q2-question",
          answer: "life-faq-park-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeParkGame",
          slug: "park-go-to-park-with-family-members",
          question: "life-faq-park-q3-question",
          answer: "life-faq-park-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeParkGame",
          slug: "life-game-ranking",
          question: "life-faq-park-q4-question",
          answer: "life-faq-park-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeTech",
          slug: "life-phone-functions-need-to-participate",
          question: "life-faq-tech-q1-question",
          answer: "life-faq-tech-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeTech",
          slug: "life-mobile-device",
          question: "life-faq-tech-q2-question",
          answer: "life-faq-tech-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeTech",
          slug: "life-participate-via-web",
          question: "life-faq-tech-q3-question",
          answer: "life-faq-tech-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeTech",
          slug: "life-location-share",
          question: "life-faq-tech-q4-question",
          answer: "life-faq-tech-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeTech",
          slug: "life-health-data-share",
          question: "life-faq-tech-q5-question",
          answer: "life-faq-tech-q5-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-earn-coin",
          question: "life-faq-coin-q1-question",
          answer: "life-faq-coin-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-check-coin",
          question: "life-faq-coin-q2-question",
          answer: "life-faq-coin-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-share-coin",
          question: "life-faq-coin-q3-question",
          answer: "life-faq-coin-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-delete-app",
          question: "life-faq-coin-q4-question",
          answer: "life-faq-coin-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-other-game",
          question: "life-faq-coin-q5-question",
          answer: "life-faq-coin-q5-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-what-is-health-coin",
          question: "life-faq-coin-q6-question",
          answer: "life-faq-coin-q6-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-gifts-can-redeem",
          question: "life-faq-coin-q7-question",
          answer: "life-faq-coin-q7-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-how-to-redeem",
          question: "life-faq-coin-q8-question",
          answer: "life-faq-coin-q8-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-when-can-i-redeem",
          question: "life-faq-coin-q9-question",
          answer: "life-faq-coin-q9-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-where-can-i-redeem",
          question: "life-faq-coin-q10-question",
          answer: "life-faq-coin-q10-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-why-unable-to-redeem",
          question: "life-faq-coin-q11-question",
          answer: "life-faq-coin-q11-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-exchange-gift",
          question: "life-faq-coin-q12-question",
          answer: "life-faq-coin-q12-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-redeem-under-16",
          question: "life-faq-coin-q13-question",
          answer: "life-faq-coin-q13-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-redemption-record",
          question: "life-faq-coin-q14-question",
          answer: "life-faq-coin-q14-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeCoin",
          slug: "life-coin-redeem-on-the-elife",
          question: "life-faq-coin-q15-question",
          answer: "life-faq-coin-q15-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeEnquiry",
          slug: "life-enquiry",
          question: "life-faq-enquiry-q1-question",
          answer: "life-faq-enquiry-q1-answer",
        },

        {
          isOpen: false,
          sectionSlug: "lifeParkGamePublic",
          slug: "park-go-to-park-with-family-members",
          question: "life-faq-park-q3-question-public",
          answer: "life-faq-park-q3-answer-public",
        },
        {
          isOpen: false,
          sectionSlug: "lifeParkGamePublic",
          slug: "life-game-ranking",
          question: "life-faq-park-q4-question-public",
          answer: "life-faq-park-q4-answer-public",
        },

        /*{
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-sync-to-elife",
          question: "life-faq-walking-q1-question",
          answer: "life-faq-walking-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-event-web",
          question: "life-faq-walking-q2-question",
          answer: "life-faq-walking-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-in-greater-bay",
          question: "life-faq-walking-q3-question",
          answer: "life-faq-walking-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-daily-mission",
          question: "life-faq-walking-q4-question",
          answer: "life-faq-walking-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-sync-afterwards",
          question: "life-faq-walking-q5-question",
          answer: "life-faq-walking-q5-answer",
        },
        {
          isOpen: false,
          sectionSlug: "lifeWalking",
          slug: "walking-sync-outside",
          question: "life-faq-walking-q6-question",
          answer: "life-faq-walking-q6-answer",
        },*/
      ],
      tab: "",
      tabs: [
        {
          key: "public",
          name: "elife.faq.public",
        },
        {
          key: "school",
          name: "elife.faq.school",
        },
      ],
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
  }

  toggleQuestion(sectionSlug, slug) {
    const newQuestions = [...this.state.questions];
    const questionIndex = newQuestions.findIndex(
      (question) =>
        question.slug === slug && question.sectionSlug === sectionSlug
    );
    if (questionIndex === -1) return;
    newQuestions[questionIndex].isOpen = !newQuestions[questionIndex].isOpen;
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleSectionQuestions(slug) {
    const newQuestions = [...this.state.questions];
    const sectionQuestions = newQuestions.filter(
      (question) => question.sectionSlug === slug
    );
    const sectionOpen = sectionQuestions.some((question) => !question.isOpen);
    sectionQuestions.forEach((question) => {
      question.isOpen = sectionOpen;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleAll() {
    const newQuestions = [...this.state.questions];
    const open = newQuestions.some((question) => !question.isOpen);
    newQuestions.forEach((question) => {
      question.isOpen = open;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  scrollToQuestion() {
    const navHeight = window.innerWidth > 1260 ? 80 : 64;
    const offsetTop = navHeight + 20;
    let anchor =
      this.getParameterByName("section") ?? window.location.href.split("#")[1];
    anchor = anchor?.split("?")[0];
    if (!anchor) return;

    const type = anchor?.includes("_") ? "question" : "section";
    const sectionSlug = type === "question" ? anchor.split("_")[0] : anchor;
    if (!sectionSlug) return;

    if (type === "question") {
      const questionSlug = anchor.split("_")[1]?.split("?")[0];
      console.log({ questionSlug });
      const questionElement = document.getElementById(
        `faq-question-container-${sectionSlug}-${questionSlug}`
      );
      if (!questionElement) return;

      setTimeout(() => {
        this.toggleQuestion(sectionSlug, questionSlug);
        window.scrollTo({
          top: questionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }

    if (type === "section") {
      this.toggleSectionQuestions(sectionSlug);
      const sectionElement = document.getElementById(
        `faq-section-${sectionSlug}`
      );
      if (!sectionElement) return;
      setTimeout(() => {
        window.scrollTo({
          top: sectionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    this.scrollToQuestion();
    this.setState({
      tab: this.state.tabs[0].key,
    });
  }

  handleSwitchTab(tab) {
    this.setState({
      tab,
    });
  }

  render() {
    const { locale, t } = this.props;
    let { eHealthSite, registerLink, ConsentLink, stepsLink, walkingLink } =
      this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=en";
      ConsentLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/sharing-consent/index.html";
      stepsLink = "https://www.10000stepsaday.hk/en/tutorial/";
      walkingLink = "https://www.10000stepsaday.hk/?lang=en";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=sc";
      ConsentLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/sharing-consent/index.html";
      stepsLink = "https://www.10000stepsaday.hk/zh-hans/tutorial/";
      walkingLink = "https://www.10000stepsaday.hk/?lang=sc";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=tc";
      ConsentLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/sharing-consent/index.html";
      stepsLink = "https://www.10000stepsaday.hk/tutorial/";
      walkingLink = "https://www.10000stepsaday.hk/?lang=tc";
    }

    const answers = {
      "life-faq-login-q1-answer": (
        <>
          {t("life-faq-login-q1-answer")}
          <a href={registerLink} target="_blank">
            {registerLink}
          </a>
        </>
      ),

      "life-faq-coin-q7-answer": (
        <>
          {t("life-faq-coin-q7-answer-p1")}
          <a href="https://app.ehealth.gov.hk/elife-redemption" target="_blank">
            https://app.ehealth.gov.hk/elife-redemption
          </a>
          {t("life-faq-coin-q7-answer-p2")}
        </>
      ),
      "life-faq-coin-q9-answer": (
        <>
          {t("life-faq-coin-q9-answer-p1")}
          <a href="https://app.ehealth.gov.hk/elife-redemption" target="_blank">
            https://app.ehealth.gov.hk/elife-redemption
          </a>
          {t("life-faq-coin-q9-answer-p2")}
        </>
      ),
      "life-faq-coin-q10-answer": (
        <>
          {t("life-faq-coin-q10-answer-p1")}
          <a href="https://app.ehealth.gov.hk/elife-redemption" target="_blank">
            https://app.ehealth.gov.hk/elife-redemption
          </a>
          {t("life-faq-coin-q10-answer-p2")}
        </>
      ),
      "life-faq-coin-q11-answer": (
        <>
          {t("life-faq-coin-q11-answer-p1")}
          <ol className="orderlist-style">
            <li>{t("life-faq-coin-q11-answer-p2")}</li>
            <li>{t("life-faq-coin-q11-answer-p3")}</li>
            <li>{t("life-faq-coin-q11-answer-p4")}</li>
          </ol>
          {t("life-faq-coin-q11-answer-p5")}
        </>
      ),
      "life-faq-coin-q5-answer": (
        <>
          {t("life-faq-coin-q5-answer-p1")}
          <a
            href="https://app.ehealth.gov.hk/elife-step-challenge"
            target="_blank"
          >
            https://app.ehealth.gov.hk/elife-step-challenge
          </a>
          {t("life-faq-coin-q5-answer-p2")}
        </>
      ),
      "life-faq-walking-q1-answer": (
        <>
          {t("life-faq-walking-q1-answer-1")}
          <a href={stepsLink} target="_blank">
            {t("life-faq-walking-q1-answer-2")}
          </a>
          {t("life-faq-walking-q1-answer-3")}
        </>
      ),
      "life-faq-walking-q2-answer": (
        <>
          {t("life-faq-walking-q2-answer-1")}
          <a href={walkingLink} target="_blank">
            {t("life-faq-walking-q2-answer-2")}
          </a>
          {t("life-faq-walking-q2-answer-3")}
        </>
      ),
    };

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/life-faq-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/life-faq-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row tabs-row">
            <div class="tabs">
              {this.state.tabs?.map((tab) => {
                return (
                  <div
                    className={`tab ${
                      this.state.tab === tab.key ? "active" : ""
                    }`}
                    onClick={() => {
                      this.handleSwitchTab(tab.key);
                    }}
                  >
                    {t(tab.name)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="container faq-container">
          <div className="row">
            <div className="faq-expand-all">
              <span className="faq-expand" onClick={() => this.toggleAll()}>
                {this.state.questions.some((question) => !question.isOpen)
                  ? t("Expand all section")
                  : t("Collapse all section")}
              </span>
            </div>
            {this.state.sections.map((section) => {
              if (section.tab !== this.state.tab) return null;

              return (
                <div id={`faq-section-${section.slug}`} className="faq-section">
                  <div className="faq-title">
                    <h3>{t(section.title)}</h3>
                    <span
                      className="faq-expand"
                      onClick={() => this.toggleSectionQuestions(section.slug)}
                    >
                      {this.state.questions.some(
                        (question) =>
                          question.sectionSlug === section.slug &&
                          !question.isOpen
                      )
                        ? t("Expand this section")
                        : t("Collapse this section")}
                    </span>
                  </div>
                  {this.state.questions
                    .filter((question) => question.sectionSlug === section.slug)
                    .map((question) => {
                      return (
                        <div
                          id={`faq-question-container-${question.sectionSlug}-${question.slug}`}
                          className="faq-question-container"
                        >
                          <div
                            className="faq-question"
                            onClick={() =>
                              this.toggleQuestion(
                                question.sectionSlug,
                                question.slug
                              )
                            }
                          >
                            {t(question.question)}
                            <img
                              className="faq-expand-icon"
                              src={load(
                                `${
                                  question.isOpen
                                    ? "./resources/images/collapse-button.png"
                                    : "./resources/images/collapse-button-open.png"
                                }`
                              )}
                            />
                          </div>
                          {question.isOpen && (
                            <div className="faq-answer">
                              {answers[question.answer] || t(question.answer)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let str = window.location.href;
  str = str.split("?")[0].split("#").reverse()[0];
  if (str == "faq-section5") {
    console.log(str);
    var appointment_faq = document
      .getElementById("faq-section5-03")
      .getElementsByClassName("faq-question")[0];
    appointment_faq.click();
    console.log(appointment_faq);
  }
});
export default PageHOC(LifeFaq);
