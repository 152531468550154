import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

// NewsItem Component
const NewsItem = ({ item, locale, t }) => {
  return (
    <div
      className={`col-sm-4 news-item news-item-${item.class} news-item-${locale}-${item.class}`}
    >
      <a href={item.newLink}>
        <img
          className="whats-new-thumbnail"
          src={load(`./resources/images/whats-new/${item.newThumb}`)}
          alt={t(item.newTitle)}
        />
      </a>
      <div className="whats-new-title">
        <a href={item.newLink}>{t(item.newTitle)}</a>
      </div>
      <div className="whats-new-data">{t(item.newDate)}</div>
    </div>
  );
};

class WhatsNewPage extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  constructor(props) {
    super(props);
    const { locale } = this.props;
    this.state = {
      tab: "",
      currentPage: 1,
      itemsPerPage: 9,
      eHealthNewItem: [
        {
          newThumb: `news-radi-report.png`,
          newTitle: `news title - Investigations radiology reports`,
          newLink: `/clinical-investigations`,
          newDate: `April 2025`,
          class: true,
        },
        // {
        //   newThumb: `news-cm-allergy.png`,
        //   newTitle: `news title - Chinese Medicine allergy`,
        //   newLink: `/clinical-allergy`,
        //   newDate: `March 2025`,
        //   class: true,
        // },
        {
          newThumb: `news-under16-registration.png`,
          newTitle: `news title - Under 16 reg birth cert`,
          newLink: `/caregiver`,
          newDate: `February 2025`,
          class: false,
        },
        {
          newThumb: `news-newborn-pack.png`,
          newTitle: `news title - New born pack news title`,
          newLink: `/gifts-redemption`,
          newDate: `February 2025`,
          class: false,
        },
        {
          newThumb: `news-under16-health-coins.png`,
          newTitle: `news title - Under 16 health coins campaign`,
          newLink: `/gifts-redemption`,
          newDate: `February 2025`,
          class: false,
        },
        {
          newThumb: `${locale}/news-lab-report-jan24.png`,
          newTitle: `news title - Investigation View laboratory records`,
          newLink: `/clinical-investigations`,
          newDate: `January 2025`,
          class: false,
        },
      ],
      eLifeNewItem: [
        {
          newThumb: `elife/elife-redemption-event.png`,
          newTitle: `elife news title - general redemption event`,
          newLink: `/elife-redemption`,
          newDate: `February 2025`,
        },
        {
          newThumb: `elife/elife-general-go-to-park.png`,
          newTitle: `elife news title - general go to park capture event`,
          newLink: `/elife-overview#overview-park-container`,
          newDate: `September 2024`,
        },
      ],
      eHealthProNewItem: [
        {
          newThumb: `pro/news-pro-assistant.png`,
          newTitle: `pro news title - new mini assistant`,
          newLink: `/ehealth-pro-mini-assistant`,
          newDate: `April 2025`,
          class: true,
        },
        {
          newThumb: `pro/news-pro-enrolled-program.png`,
          newTitle: `pro news title - enrolled health programmes`,
          newLink: `/ehealth-pro-account-management`,
          newDate: `April 2025`,
          class: true,
        },
        {
          newThumb: `pro/news-pro-broadcast.png`,
          newTitle: `pro news title - new Professional Broadcast`,
          newLink: `/ehealth-pro-notification-center`,
          newDate: `April 2025`,
          class: true,
        },
      ],

      tabs: [
        {
          key: "ehealth-app",
          name: "eHealth App",
        },
        {
          key: "e-life",
          name: "e+Life",
        },
        {
          key: "ehealth-pro",
          name: "eHealth Pro",
        },
      ],
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  handleSwitchTab(tab) {
    this.setState({
      tab,
      currentPage: 1, // Reset page number when switching tabs
    });
  }

  handlePageChange(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }

  handleNextPage() {
    this.setState((prevState, props) => {
      const { tab, itemsPerPage } = prevState;
      const items = this.getItemsByTab(tab);
      const pageNumbers = Math.ceil(items.length / itemsPerPage);
      if (prevState.currentPage < pageNumbers) {
        return { currentPage: prevState.currentPage + 1 };
      }
      return null;
    });
  }

  handlePreviousPage() {
    this.setState((prevState) => {
      if (prevState.currentPage > 1) {
        return { currentPage: prevState.currentPage - 1 };
      }
      return null;
    });
  }

  componentDidMount() {
    this.setState({
      tab: this.state.tabs[0].key,
    });
  }

  getItemsByTab(tab) {
    const { eHealthNewItem, eLifeNewItem, eHealthProNewItem } = this.state;
    switch (tab) {
      case "ehealth-app":
        return eHealthNewItem;
      case "e-life":
        return eLifeNewItem;
      case "ehealth-pro":
        return eHealthProNewItem;
      default:
        return [];
    }
  }

  render() {
    const { locale, t } = this.props;
    const { tab, tabs, currentPage, itemsPerPage } = this.state;
    const items = this.getItemsByTab(tab);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const renderPageNumbers = () => {
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(items.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }
      return (
        <div className="pagination">
          <span
            onClick={currentPage > 1 ? this.handlePreviousPage : null}
            className={currentPage === 1 ? "disabled" : ""}
          >
            <img src={load(`./resources/images/pagination-prev.png`)} />
          </span>
          {pageNumbers.map((number) => (
            <span
              key={number}
              id={number}
              onClick={this.handlePageChange}
              className={currentPage === number ? "active" : ""}
            >
              {number}
            </span>
          ))}
          <span
            onClick={
              currentPage < pageNumbers.length ? this.handleNextPage : null
            }
            className={currentPage === pageNumbers.length ? "disabled" : ""}
          >
            <img src={load(`./resources/images/pagination-next.png`)} />
          </span>
        </div>
      );
    };

    return (
      <div
        className={`page-container page-container-${locale} whats-new-page`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/${locale}/what-new-banner-desktop.jpg`
            )}
            alt="Banner"
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/${locale}/what-new-banner-mobile.jpg`
            )}
            alt="Mobile Banner"
          />
        </div>

        <div className="container-fluid whats-new-container">
          <div className="container">
            <div className="tabs">
              {tabs.map((tabItem) => (
                <div
                  key={tabItem.key}
                  className={`tab ${tab === tabItem.key ? "active" : ""}`}
                  onClick={() => this.handleSwitchTab(tabItem.key)}
                >
                  {t(tabItem.name)}
                </div>
              ))}
            </div>

            <div className="tab-content">
              {["ehealth-app", "e-life", "ehealth-pro"].map((key) => {
                const activeItems = this.getItemsByTab(key);
                return (
                  <div key={key} className={`${tab === key ? "active" : ""}`}>
                    <div className="news-item-container">
                      {activeItems
                        .slice(indexOfFirstItem, indexOfLastItem)
                        .map((item) => (
                          <NewsItem
                            key={item.newTitle}
                            item={item}
                            locale={locale}
                            t={t}
                          />
                        ))}
                    </div>
                    {renderPageNumbers()}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="empty-space-100"></div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(WhatsNewPage);
