import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class QRCodeScannerPage extends Component {
  constructor(props) {
    super(props);
    this.youtubeContainer = React.createRef();
  }

  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "qr_code_slider_phone1.png",
      "qr_code_slider_phone2.png",
      "qr_code_slider_phone3.png",
      "qr_code_slider_phone4.png",
    ];
    const sliderImageAlts = ["Screenshot of QR Code Scanner"];
    const sliderImageIds = ["img1QRCodeCScannerSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"qrcode-scanner-bg.png"}
        sliderID="QRCodeCScannerSlider"
        sliderTitle={t("QR Code / Licence Scanner")}
        sliderTitleID={"textQRCodeCScannerSliderTitle"}
        sliderText={t(" ")}
        sliderTextID="textQRCodeCScannerSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} `}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textChildGrowthSection1Title"
                  className="second-feature-title"
                >
                  {t("Scan/Show eHealth QR code")}
                </div>
                <div
                  id="textChildGrowthSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Scan/show the relevant eHealth QR codes to perform various functions in the eHealth App"
                  )}
                </div>
              </div>

              <div data-aos="" className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/${locale}/qr_scanner_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="0"
                  className="absolute-image middle"
                  src={load(`./resources/images/qr_scanner_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/qr_scanner_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container scanner-features-container">
            <h3>{t("How can we utilize ‘Scan QR Code'?")}</h3>
            <h4 className="scanner-features-title">
              {t("Scan eHealth QR code")}
            </h4>
            <div className="row vertical-align services-detail-row">
              <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/scan-qr-add-member.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Add my family members")}</h4>
                  {t(
                    "Manage my family members’ health records by scanning their QR codes"
                  )}
                </div>
              </div>
              <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/scan-qr-consent.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Give sharing consent")}</h4>
                  {t(
                    "Allow healthcare providers to access and deposit your health records by scanning their QR codes"
                  )}
                </div>
              </div>
            </div>

            <h4 className="scanner-features-title">{t("Others")}</h4>
            <div className="row vertical-align services-detail-row bottom-line-row">
              <div className="col-sm-6 vertical-align">
                <img
                  src={load(`./resources/images/scan-qr-licence-scanner.png`)}
                />
                <div className="qr-services-detail">
                  <h4>{t("Licence Scanner")}</h4>
                  {t(
                    "Scan QR codes recognised by the Department of Health to verify and obtain relevant licence information"
                  )}
                  <a href="ehealth-faq#functions_licence-scanner">
                    {t("Learn more")} <i class="arrow right"></i>
                  </a>
                </div>
              </div>
              {/* <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/scan-qr-med-cert.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Verify medical certificate")}</h4>
                  {t(
                    "Scan QR code on the medical certificate issued by designated healthcare provider for verification"
                  )}
                </div>
              </div> */}
            </div>
            <h3>{t("How can we utilize ‘My QR Code'?")}</h3>
            <div className="row vertical-align services-detail-row">
              <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/my-qr-add-member.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Allow adding family members")}</h4>
                  {t(
                    "Caregiver can scan the QR code to add you as a family member. If you accept the caregiver request, the caregiver can review your eHR via the eHealth App."
                  )}
                </div>
              </div>
              <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/my-qr-attendance.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Take e-attendance")}</h4>
                  {t(
                    "Show your QR code for taking attendance and verifying identify"
                  )}
                  <span className="qr-service-note">
                    {t(
                      "*Applicable to healthcare providers that have completed relevant technical setup"
                    )}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="row vertical-align services-detail-row">
              <div className="col-sm-6 vertical-align">
                <img src={load(`./resources/images/my-qr-registration.png`)} />
                <div className="qr-services-detail">
                  <h4>{t("Complete e-registration")}</h4>
                  {t(
                    "Show your QR code for user registration at designated healthcare providers"
                  )}
                  <span className="qr-service-note">
                    {t(
                      "*Applicable to healthcare providers that have completed relevant technical setup"
                    )}
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(QRCodeScannerPage);
