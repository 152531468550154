import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import "../../../resources/styles/elifeRemption.scss";

class LifeTutorial extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/us/app/e-life/id6502993463",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life",
    huaweiDownloadURL: "https://appgallery.huawei.com/app/C110183237",
  };
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  constructor(props) {
    super(props);
    this.state = {
      tab: "",
      tabs: [
        {
          key: "above",
          name: "16 years old or above",
        },
        {
          key: "under",
          name: "Under 16 years old",
        },
      ],
    };
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
  }

  handleSwitchTab(tab) {
    this.setState({
      tab,
    });
  }

  componentDidMount() {
    this.setState({
      tab: this.state.tabs[0].key,
    });
  }

  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    const { locale, t } = this.props;
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
    } = this.props;
    let { registerLink } = this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/register-online.html";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/register-online.html";
    }

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/us/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/cn/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/e-life/id6502993463"
        : "https://apps.apple.com/hk/app/e-life/id6502993463";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.life&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/app/C110183237"
        : "https://appgallery.huawei.com/app/C110183237";
    }

    return (
      <div
        className={` page-container page-container-${locale} life-redemption-page`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/elife/${locale}/life-redemption-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/elife/${locale}/life-redemption-banner-mobile.jpg`
            )}
          />
        </div>
        <div className="container-fluid gift-redemption-intro-container">
          <div className="container">
            <div className="row gift-redemption-intro">
              <b>{t("Gifts redemption intro bold")}</b>
              <br />
              <br />
              {t("Gifts redemption intro")}
            </div>

            <img
              className="redemption-graphic"
              src={load(
                `./resources/images/elife/life-redemption-main-graphic.png`
              )}
            />

            <div className="gift-redemption-video-container">
              <div
                ref={this.youtubeContainer}
                className="second-youtube-container"
              >
                <iframe
                  key={locale}
                  className="second-responsive-iframe"
                  title={t("Video des")}
                  src="https://www.youtube.com/embed/jAJw8G-aoew"
                  alt={t("Video")}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid gift-redemption-details-container">
          <div className="container">
            <div className="row gift-redemption-details">
              <h4 className="">{t("gift redemption title")} </h4>
              <div className="">{t("gift redemption details intro")}</div>
              <div className="">
                {t("gift redemption details intro date range")}
              </div>
              <br />
              <div className="col-12 col-sm-8 gift-single-col">
                <div className="row gift-single-col-content">
                  <h5 className="">{t("gift 1500 coins title")} </h5>
                  <div className=" col-12 col-sm-6">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-bag.png`
                      )}
                    />
                    <br />
                    {t("Recycle Bag (Foldable)")}
                  </div>

                  <div className="col-12 col-sm-6">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-towel.png`
                      )}
                    />
                    <br />
                    {t("Cooling Towel")}
                  </div>

                  <div className="col-12 col-sm-6">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-pouch.png`
                      )}
                    />
                    <br />
                    {t("Eco Umbrella Pouch (Foldable)")}
                  </div>

                  <div className="col-12 col-sm-6">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-soft-tape.png`
                      )}
                    />
                    <br />
                    {t("Mini Soft Tape Measure")}
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4 gift-single-col">
                <div className="row gift-single-col-content">
                  <h5 className="">{t("gift 2500 coins title")} </h5>
                  <div className=" col-12 ">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-cutlery-set.png`
                      )}
                    />
                    <br />
                    {t("Eco Portable Cutlery Set")}
                  </div>

                  <div className="col-12">
                    <img
                      src={load(
                        `./resources/images/elife/gift/redemption-gift-picnic-mat.png`
                      )}
                    />
                    <br />
                    {t("Portable Picnic Mat")}
                  </div>
                </div>
              </div>
            </div>

            <h4 className="">{t("gift redemption location title")} </h4>
            <br />
            <div className="gift-redemption-location-list">
              <div className="row location-list-header">
                <div className="col-sm-4">{t("Designated Location")}</div>
                <div className="col-sm-8">
                  {t("Location of Gift Redemption Machine")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Harbour Road Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Harbour Road Sports Centre address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Victoria Park Swimming Pool")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Victoria Park Swimming Pool address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Kwun Tong Swimming Pool")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Kwun Tong Swimming Pool address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Kowloon Park Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Kowloon Park Sports Centre address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Sham Shui Po Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Sham Shui Po Sports Centre address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Tiu Keng Leng Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Tiu Keng Leng Sports Centre address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Che Kung Temple Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Che Kung Temple Sports Centre address")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 location-list-name">
                  <b>{t("Tsuen Wan Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address">
                  {t("Tsuen Wan Sports Centre address")}
                </div>
              </div>
              <div className="row no-border-row">
                <div className="col-sm-4 location-list-name last-address">
                  <b>{t("Yuen Long Sports Centre")}</b>
                </div>
                <div className="col-sm-8 location-list-address last-address">
                  {t("Yuen Long Sports Centre address")}
                </div>
              </div>
            </div>
          </div>
          <div className="container redemption-terms">
            <strong>{t("gift Redemption terms title")}</strong>
            <ol className="orderlist-style">
              <li>{t("gift redemption terms 1")}</li>
              <li>{t("gift redemption terms 2")}</li>
              <li>{t("gift redemption terms 3")}</li>
              <li>{t("gift redemption terms 4")}</li>
              <li>{t("gift redemption terms 5")}</li>
              <li>{t("gift redemption terms 6")}</li>
              <li>{t("gift redemption terms 7")}</li>
              <li>{t("gift redemption terms 8")}</li>
              <li>{t("gift redemption terms 9")}</li>
            </ol>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(LifeTutorial);
